import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    users: [],
    usersCount: 0,
    user: {},
    userSave: {
      message: null,
      status: null,
      id: null,
    },
    forgotResult: {
      message: null,
      status: null,
    },
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getUsersCount(state) {
      return state.usersCount
    },
    getUser(state) {
      return state.user
    },
    getUserSaveStatus(state) {
      return state.userSave
    },
    getForgotResult(state) {
      return state.forgotResult
    },
  },
  mutations: {
    USERS_LIST(state, data) {
      state.users = data
    },
    USER_VIEW(state, data) {
      state.user = data
    },
    USERS_COUNT(state, data) {
      state.usersCount = data
    },
    USER_SAVE(state, data) {
      state.userSave = data
    },
    AUTH_FORGOT(state, data) {
      state.forgotResult = data
    },
  },
  actions: {
    usersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/core/users', data)
        .then(response => {
          commit('USERS_LIST', response.data.data)
          commit('USERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    userView({ commit }, id) {
      const data = JSON.stringify({
        select: [
          'users.id AS id',
          'users.name AS name',
          'users.email AS email',
          'users.status AS status',
          'users.id_user_types AS id_user_types',
        ],
        where: {
          'users.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/core/users', data)
        .then(response => {
          commit('USER_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    userSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/core/users/saveData', data)
        .then(response => {
          commit('USER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    forgotPassword({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
          .post('/Auth/forgot', data)
          .then(response => {
            commit('AUTH_FORGOT', response.data)
          })
          .catch(error => console.error(error))
    },
  },
}
